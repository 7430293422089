import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import SidebarMenu from "../SidebarMenu";
import '../../Addv.css';

const UploadPod = () => {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const role = sessionStorage.getItem('role');
    const user = sessionStorage.getItem('userid');  

    useEffect(() => {
        getBranch1();
        getUserlist();
    }, []);


    const [Branch1data, setBranch1] = useState();
    const getBranch1 = async () => {        
        const API_PATH = `${SERVER_URL}pod.php`;
        const passval = {
            type: 'getbranch1',
            user: user,
            role: role
        };
        await axios
            .post(API_PATH, passval)
            .then(res => {
                // console.log(res); 
                setBranch1(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const [userlist, setuserlist] = useState();
    const getUserlist = async () => {    
        const API_PATH = `${SERVER_URL}pod.php`;
        const passval = {
            type: 'getuserlist',
            user: user,
            role: role
        };
        await axios
            .post(API_PATH, passval)
            .then(res => {
                 console.log(res);
                setuserlist(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const defaultValues = {
        branch: '39',
        manager: user,
        updated_on: '',
        file: ''
    }

    const [poddata, setpoddata] = useState(defaultValues);

    const MultipleFileChange = (e) => {
        setpoddata({...poddata,file:e.target.files})
    } 

    
    const [err_msg, set_err_msg] = useState();
    const submit_pod = async () => {
        const API_PATH = `${SERVER_URL}pod.php`;
        if(!poddata.file){
            set_err_msg('Please select a file!');
            return false; // stop here... 
        }else{
            console.log(poddata);
            set_err_msg("Processing......");
            const passval = {
                type: 'uploadPOD',
                branch: poddata.branch,
                manager: poddata.manager,
                updated_on: poddata.updated_on,
                file: poddata.file
            };
            await axios
                .post(API_PATH, passval)
                .then(res => {
                    console.log(res);
                    if(res.data.status == '1'){
                        uploadFiles(res.data.id);
                    }

                })
                .catch((err) => {
                    console.log(err);
                })
        }     
    }

    const uploadFiles = async(id) => {
        const API_PATH = `${SERVER_URL}pod_upload.php`;
        const data = new FormData();
        for (let i = 0; i < poddata.file.length; i++) {
            data.append(`filelist[${i}]`, poddata.file[i]);
        }
        
        data.append("pod_id", id);
        data.append("type", "uploadFILES");

        await axios
            .post(API_PATH, data)
            .then(res => {
                console.log(res);
                set_err_msg(res.data.message);
                if(res.data.status == '1'){
                    window.location.reload(); 
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return(
        <div className="wrapper-holder">
            {<SidebarMenu/>}

            <div className="main-panel ps-container">
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    
                                    <div className="card-header card-header-primary">
                                        {/* <h4 className="card-title">Upload POD</h4> */}
                                        <p className="card-category">Complete vehicle profile</p>                                     
                                    </div>

                                    <div className="card-body">
                                        <form id="myform" encType="multipart/form-data">
                                            <div className="row">
                                                <div className="col-md-6">                                                    
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Account Name</label>
                                                        
                                                        <select id="branch1" name="branch1" onChange={(e)=>setpoddata({...poddata,branch1:e.target.value})}  className="form-control " data-live-search="true" disabled>
                                                            {
                                                                Branch1data && Branch1data != ""?(
                                                                    <>
                                                                        {
                                                                            Branch1data.map(data => (
                                                                                <option value={data.id} selected={data.id==poddata.branch?true:false}>{data.branch}</option>
                                                                            ))
                                                                        }
                                                                    </>
                                                                ):null
                                                            }
                                                            
                                                            <option value="DXB">DXB</option>
                                                        </select>   
                                                        
                                                        <input type="hidden"  id="branch" name="branch" value={poddata.branch} onChange={(e)=>setpoddata({...poddata,branch:e.target.value})} className="form-control"></input>
                                                        <input type="hidden"  id="manager" name="manager" value={poddata.manager} onChange={(e)=>setpoddata({...poddata,manager:e.target.value})} className="form-control"></input>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-md-6">                                                       
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Account Name</label>
                                                        <select id="manager1" name="manager1" onChange={(e)=>setpoddata({...poddata,manager1:e.target.value})}  className="form-control " data-live-search="true" disabled>
                                                            {
                                                                userlist && userlist != ""?(
                                                                    <>
                                                                        {
                                                                            userlist.map(data => (
                                                                                <option value={data.id} selected={data.id==poddata.manager?true:false}>{data.user}</option>
                                                                            ))
                                                                        }
                                                                        
                                                                    </>
                                                                ):null
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating datefiled">AWB Date</label>
                                                        <input type="date" id="updated_on" name="updated_on" value={poddata.updated_on} onChange={(e)=>setpoddata({...poddata,updated_on:e.target.value})} className="form-control" />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Upload File <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="file" id="pod" onChange={(e) => MultipleFileChange(e)} multiple className="custom-file-input"></input>
                                                    </div>
                                                </div>                                               

                                                <div className="col-md-12" style={{textAlign: 'right'}}>
                                                    <p style={{ color: "red", margin: "0", marginTop: "12px" }}>{err_msg}</p>
                                                    <a className="btn btn-primary pull-right" onClick={submit_pod} style={{color: '#fff'}}>Add POD</a>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default UploadPod;